import React, { createContext, useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import enMessages from './messages/en.json';
import esMessages from './messages/es.json';

export type Locale = 'en' | 'es';
type Messages = typeof enMessages;

interface I18nContextType {
    locale: Locale;
    setLocale: (locale: Locale) => void;
}

const messages: Record<Locale, Messages> = {
    en: enMessages,
    es: esMessages,
};

const I18nContext = createContext<I18nContextType | undefined>(undefined);

export function I18nProvider({ children }: { children: React.ReactNode }) {
    const [locale, setLocale] = useState<Locale>('en');

    return (
        <I18nContext.Provider value={{ locale, setLocale }}>
            <IntlProvider 
                messages={messages[locale]} 
                locale={locale}
                defaultLocale="en"
            >
                {children}
            </IntlProvider>
        </I18nContext.Provider>
    );
}

export function useI18n() {
    const context = useContext(I18nContext);
    if (!context) {
        throw new Error('useI18n must be used within an I18nProvider');
    }
    return context;
}
